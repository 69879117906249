import React from "react"
import ContentLoader from "react-content-loader"

const ContentBlogLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={900}
    height={600}
    viewBox="0 0 900 600"
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
    style={{ width: '90%'}}
    {...props}
  >
    <rect x="150" y="53" rx="6" ry="6" width="427" height="15" /> 
    <rect x="37" y="77" rx="7" ry="7" width="861" height="139" /> 
    <rect x="38" y="225" rx="0" ry="0" width="616" height="8" /> 
    <rect x="38" y="238" rx="0" ry="0" width="567" height="8" /> 
    <rect x="38" y="252" rx="0" ry="0" width="616" height="8" />
    <rect x="38" y="252" rx="0" ry="0" width="616" height="8" />

   
  </ContentLoader>
)

ContentBlogLoader.metadata = {
  name: "Nitish Sharma", 
  github: "Nitz2611", 
  description: "Category with image and description", 
  filename: "CategoryLoader" 
}

export default ContentBlogLoader;


