// StickyNote.js
import React, { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention';
import '../../utils/stickyCss.scss';

var Font = Quill.import("formats/font");
// We do not add Aref Ruqaa since it is the default
Font.whitelist = ['verdana', 'georgia', 'Timesnewroman','madeEvolve','dutchTulips','quickgram','crackerBanana'];
Quill.register(Font, true);

var Size = Quill.import("formats/size");
Size.whitelist = [
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];

Quill.register(Size, true);

const Parchment = Quill.import("parchment");
const boxAttributor = new Parchment.Attributor.Class("box", "line", {
  scope: Parchment.Scope.INLINE,
  whitelist: ["solid", "double", "dotted"],
});
Quill.register(boxAttributor);

const atValues = [
  { id: 0, value: "barcode" },
  { id: 1, value: "customername" },
  { id: 2, value: "licensenumber" },
  { id: 3, value: "netweight" },
  { id: 4, value: "packageid" },
  { id: 5, value: "price" },
  { id: 6, value: "productname" },
  { id: 7, value: "supplierid" },
];

const StickyCustomToolbar = () => (
  <div id="toolb">
    <select className="ql-size">
      {Size.whitelist.map((size, index) => {
        const sizeWithoutPx = size.replace("px", "");
        return (
          <option key={index} value={size} defaultValue={size.includes("12")}>
            {sizeWithoutPx}
          </option>
        );
      })}
    </select>
  </div>
);

const modules = {
  toolbar: {
    container: "#toolb",
  },
};

const formats = [
  "bold",
  "underline",
  "italic",
  "font",
  "size",
  "indent",
  "box",
  // "mention",
];

function StickyNote ({quillInstance_note,setQuillInstanceNote,inPut_note_change, noteText}) {
  // const [noteText, setNoteText] = useState('');
  const [isExpanded, setIsExpanded] = useState(true);
  const [quillInstance, setQuillInstance] = useState(null);

  const handleNoteClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
     <div className="" onClick={()=>{}}>
       <label>Add a Note</label>
     </div>
    <div style={{width:'99%',paddingBottom:10}}>
    <div className="note-headers" onClick={()=>{}}></div>
      <StickyCustomToolbar />
      <ReactQuill
        theme="snow"
        placeholder="Type your note here..."
        value={noteText}
        onChange={inPut_note_change}
        modules={modules}
        formats={formats}
        ref={(quills) => {
            if (quills) {
              setQuillInstanceNote(quills.getEditor());
            }
          }}
      />
    </div>
    </>
  );
};

export default StickyNote;
