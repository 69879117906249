import React, { useState } from 'react';
import { Modal, Form, Input, Button, Spin, Select, DatePicker, Checkbox } from "antd";
import { antIcon } from '../Loader/antIcon';
import { createTopImage } from '../../Actions/reflections';
import { useDispatch } from 'react-redux';

const FormItems = Form.Item;
const pageName = "reflections";

export default function AddTopImage({formTop,setImageFile,setIsUploading,isUploading,imageLoading, setImageLoading, setImageSuccess,successImage}) {
    const [isupdating,setIsUpdating] = useState(false);
    const [topImage,setTopImage] = useState("");
    const dispatch = useDispatch();

    function onFinishCreateTopImage(e) {
            dispatch(
              createTopImage(
                topImage,
                setImageLoading,
                setImageSuccess,
                setTopImage,
                setImageFile
              )
            ).then((res) => {
            });
    }
    
    const isFileChosen = topImage !== '';

    const beforeUpload = (file) => {
      // Perform additional validation if needed
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        // Display an error message or handle invalid file type
        console.error('Invalid file type. Please choose an image.');
        return false;
      }
      return true;
    };

  return (
    <div style={{}}>
        <Form
            initialValues={{ remember: true }}
            name="blog_creation"
            form={formTop}
            onFinish={onFinishCreateTopImage}
            layout="inline"
            style={{ width: "80%", margin: "auto", paddingTop: "20px",borderWidth:1 }}
          >
           <div className='add_top_image'>
            <div className="form-group">
              <label>Top Image</label>
              <FormItems name={`images`}>
                <input
                  type={`file`}
                  onChange={(e) => {
                   const file = e.target.files[0];
                  if (beforeUpload(file)) {
                    setTopImage(file);
                    const reader = new FileReader();
                    reader.onload = function (e) {
                      setImageFile(reader.result);
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                  accept=".jpg,.png,.jpeg"
                  placeholder="Please enter your title"
                  className="blog-inputs"
                  style={{width: '100%'}}
                />
              </FormItems>
            </div>
     
            <div className="form-group w-10 text-center">
              <Button
                type="primary"
                className="add_image__button"
                htmlType="submit"
                disabled={!isFileChosen}
              >
                {imageLoading === true ? <Spin indicator={antIcon} /> : ""}
                {isupdating == true
                  ? `Update top image`
                  : "Add top Image"}
              </Button>
              <div style={{ marginTop: 30 }}>
            {/* Display success message */}
            {successImage &&(
              <span style={{ color: 'green' }}>Image uploaded successfully!</span>
            )}
          </div>
            </div>
           
            </div>
          </Form>
    </div>
  )
}