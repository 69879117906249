import axios from "axios";
import { API_URL } from "../config";

import { SEND_CONTACT_MESSAGE } from "./actionTypes";

export const sendMessage = (e,successPopup,setsuccess,setLoading,ip,checkUpdateValue) => async dispatch => {
    
    try {
      let formData = new FormData();
          formData.append('name',e.name)
          formData.append('email',e.email)
          formData.append('subject',e.subject)  
          formData.append('message',e.message) 
          formData.append("email_subscription",checkUpdateValue)
          formData.append('ip_address',ip)
          setLoading(false)
    const res = await axios.post(`${API_URL}/storeContactForm`, formData)
    
      dispatch({
        type: SEND_CONTACT_MESSAGE,
        payload: res.data
      });
      successPopup()
      setsuccess(true)
      setLoading(false)
     
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  }