import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  DatePicker,
  Tag,
} from "antd";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention";
import "quill/modules/keyboard";
import axios from "axios";
import { Navigate, useParams, useLocation, Link } from "react-router-dom";
import editimage from "./../../images/edit.png";
import deleteimage from "./../../images/delete.png";
import moment from "moment";
import { API_URL } from "../../config";
import { connect, useDispatch } from "react-redux";
import { createPoems, deletePoems, fetchPoems } from "../../Actions/poems";
import { antIcon } from "../Loader/antIcon";
import {
  BoldOutlined,
  HomeOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import "../../../src/App.css";
import StickyNote from "./StickyNote";
import Note from "../../utils/Note.js";
import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, message } from "antd";

const tagOptions = ["Music", "News", "Romance", "Comedy"];
const { Option } = Select;
const FormItem = Form.Item;
const pageName = "poems";

const Font = Quill.import("formats/font");
const FontSecond = Quill.import("formats/font");
// We do not add Aref Ruqaa since it is the default
Font.whitelist = [
  "madeEvolve",
  "dutchTulips",
  "quickgram",
  "crackerBanana",
  "nursery",
];
FontSecond.whitelist = [
  "madeEvolve",
  "dutchTulips",
  "quickgram",
  "crackerBanana",
  "nursery",
  "tuckers",
  "winterLove",
  "alishader",
  "serendipity",
  "bluehill",
  "bunchOfFlowers",
  "christmasInk",
  "fairygod",
  "soulmate",
  "misteriosDelAmor",
  "soybeanut",
  "spaceCraft",
  "helloPatty",
  "jathafaDemo",
  "kgneatlyPrintedSpaced",
];
Quill.register(Font, true);
Quill.register(FontSecond, true);

var Size = Quill.import("formats/size");
Size.whitelist = [
  "4px",
  "5px",
  "6px",
  "7px",
  "8px",
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];

Quill.register(Size, true);

const Parchment = Quill.import("parchment");
const boxAttributor = new Parchment.Attributor.Class("box", "line", {
  scope: Parchment.Scope.INLINE,
  whitelist: ["solid", "double", "dotted"],
});
Quill.register(boxAttributor);

const atValues = [
  { id: 0, value: "barcode" },
  { id: 1, value: "customername" },
  { id: 2, value: "licensenumber" },
  { id: 3, value: "netweight" },
  { id: 4, value: "packageid" },
  { id: 5, value: "price" },
  { id: 6, value: "productname" },
  { id: 7, value: "supplierid" },
];
const excludedFonts = [
  "tuckers",
  "winterLove",
  "alishader",
  "serendipity",
  "bluehill",
  "bunchOfFlowers",
  "christmasInk",
  "fairygod",
  "soulmate",
  "misteriosDelAmor",
  "soybeanut",
  "spaceCraft",
  "helloPatty",
  "jathafaDemo",
];

// Filter the fonts to exclude those in the excludedFonts array
const filteredFonts = Font.whitelist.filter(
  (font) => !excludedFonts.includes(font)
);

const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-indent" value="+1" />
    <button className="ql-underline" />
    <button className="ql-italic" />
    <select className="ql-font">
      {filteredFonts.map((font, index) => (
        <option
          key={index}
          value={font}
          defaultValue={font.includes("verdana")}
        >
          {font[0].toUpperCase() + font.substr(1)}
        </option>
      ))}
    </select>
    <select className="ql-size">
      {Size.whitelist.map((size, index) => {
        const sizeWithoutPx = size.replace("px", "");
        return (
          <option key={index} value={size} defaultValue={size.includes("12")}>
            {sizeWithoutPx}
          </option>
        );
      })}
    </select>
  </div>
);
const excludedFont = [
  "dutchTulips",
  "quickgram",
  "crackerBanana",
  "nursery",
  "madeEvolve",
  "fairygod",
  "kgneatlyPrintedSpaced",
];

// Filter the fonts to exclude those in the excludedFonts array
const filteredFont = Font.whitelist.filter(
  (font) => !excludedFont.includes(font)
);

const CustomFontToolbar = () => (
  <div id="tolr">
    <select className="ql-font">
      {filteredFont.map((font, index) => (
        <option
          key={index}
          value={font}
          defaultValue={font.includes("verdana")}
        >
          {font[0].toUpperCase() + font.substr(1)}
        </option>
      ))}
    </select>
    <select className="ql-size">
      {Size.whitelist.map((size, index) => {
        const sizeWithoutPx = size.replace("px", "");
        return (
          <option key={index} value={size} defaultValue={size.includes("12")}>
            {sizeWithoutPx}
          </option>
        );
      })}
    </select>
  </div>
);

const customBindings = {
  space: {
    key: " ",
    handler: function (range, context) {
      // Insert a space at the current cursor position
      this.quill.insertText(range.index, " ");

      // Update the cursor position
      this.quill.setSelection(range.index + 1);
    },
  },
};

const modules = {
  toolbar: {
    container: "#toolbar",
    keyboard: {
      bindings: customBindings,
    },
  },
  keyboard: {
    bindings: Quill.import("modules/keyboard").bindings,
  },
};

const modules_font = {
  toolbar: {
    container: "#tolr",
  },
};
const formats = [
  "bold",
  "underline",
  "italic",
  "font",
  "size",
  "indent",
  "box",
  // "mention",
];

function Poems(props) {
  const [form] = Form.useForm();
  const [ids, setid] = useState("");
  const [form1] = Form.useForm();
  const [text_value, settext_value] = useState("");
  const [show, setshow] = useState(false);
  const [getdatas, setgetdatas] = useState([]);
  const [title_value, settitle_value] = useState("");
  const [image, setimage] = useState("");
  const [success, setsuccess] = useState(false);
  const [isupdate, setisupdate] = useState(false);
  const [forimageapi, setforimageapi] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quillInstance, setQuillInstance] = useState(null);
  const [quillInstance_note, setQuillInstanceNote] = useState(null);
  const [quillIntanceFont, setQuillInstanceFont] = useState(null);
  const [activeFormats, setActiveFormats] = useState([]);
  const [quillMessage, setQuillMessage] = useState("");
  const [titleError, setTitleError] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
  const currentYear = new Date().getFullYear(); //
  const [place, setPlace] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [customTag, setCustomTag] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [addingTag, setAddingTag] = useState(false);
  const [selectedTagIds, setSelectedTagIds] = useState([]); // New state for tag IDs

   // Fetch tags on component mount
   useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    setLoadingTags(true);
    try {
      const response = await axios.get(`${API_URL}/taglist`);
      if (response.data.result === "success") {
        setTagOptions(response.data.data.map(tag => tag.tag_name));
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    } finally {
      setLoadingTags(false);
    }
  };

  // Handle tag selection
  const handleTagSelect = async (tag) => {
    console.log("the ta infoTag", tag);
    if (tag === "other") {
      setSelectedTags((prevTags) => [...prevTags, "other"]);
    } else {
      const response = await axios.get(`${API_URL}/taglist`);
      const tagInfo = response.data.data.find((option) => option.tag_name === tag);
      console.log("the ta info", tagInfo);
      if (tagInfo) {
        setSelectedTags((prevTags) => [...prevTags, tag]);
        setSelectedTagIds((prevIds) => [...prevIds, tagInfo.tag_name]);
      }
    }
  };

 // Handle tag deselection
 const handleTagRemove = (tag) => {
  setSelectedTags((prevTags) => prevTags.filter((t) => t !== tag));
  const tagInfo = tagOptions.find((option) => option.tag_name === tag);
  if (tagInfo) {
    setSelectedTagIds((prevIds) => prevIds.filter((tag_name) => tag_name !== tagInfo.tag_name));
  }
};


  // Add custom tag when user clicks "Add"
  const handleCustomTagAdd = async () => {
    if (customTag.trim()) {
      setAddingTag(true);
      try {
        const response = await axios.post(`${API_URL}/addtag`, { tag_name: customTag });
        if (response.data.result === "success") {
          setSelectedTags((prevTags) => [
            ...prevTags.filter((tag) => tag !== "other"),
            customTag,
          ]);
          fetchTags(); // Re-fetch tags to update list with new custom tag
          setCustomTag("");
        }
      } catch (error) {
        console.error("Error adding custom tag:", error);
      } finally {
        setAddingTag(false);
      }
    }
  };

  const dispatch = useDispatch();
  const [noteText, setNoteText] = useState("");
  const [fontTitle_Change, setFontTitle_Change] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);

  const [selectedColor, setSelectedColor] = useState("#EBE2FC"); // Default color
  const [selectedCOntentColor, setSelectedContentColor] = useState("#000080"); // Default color

  // Add more colors if needed
  const colorOptions = [
    { label: "Primary", value: "#EBE2FC" },
    { label: "Second", value: "#d6dff7" },
    { label: "inky blue", value: "#000080" },
    { label: "black", value: "#000" },
    { label: "NewBlue", value: "#0F307A" },
  ];

  const filteredStoryColorOptions = colorOptions.filter(
    (colorOption) =>
      colorOption.label !== "Primary" && colorOption.label !== "Second"
  );

  const filteredNoteColorOptions = colorOptions.filter(
    (colorOption) =>
      colorOption.label !== "inky blue" &&
      colorOption.label !== "black" &&
      colorOption.label !== "NewBlue"
  );

  async function handleDeleteImage(item) {
    console.log("The printed item", item?.id);
    try {
      const response = await fetch(
        `${API_URL}/reomoveblogimage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: item?.id }), // Replace "5" with the actual image ID
        }
      );

      if (response.ok) {
        message.success("Image deleted successfully");
        // Additional logic if needed, like refreshing the image list or updating state
        handleGetPoems();
      } else {
        message.error("Failed to delete image");
      }
    } catch (error) {
      message.error("An error occurred while deleting the image");
    }
  }
  const handleContentCOlorChange = (color) => {
    setSelectedContentColor(color);
  };
  // Handle color change
  const handleColorChange = (color) => {
    setSelectedColor(color);
    // You can also save the color to the database here if needed
  };

  function inPut_note_change(e) {
    setNoteText(e);
    return;
  }

  function fontTextChange(e) {
    setFontTitle_Change(e);
    return;
  }

  const [editorData, setEditorData] = useState("");

  const handleEditorChange = (data) => {
    setEditorData(data);
  };

  const handleNoteClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    axios
      .post(`${API_URL}/getAllPosts`)
      .then((res) => {
        let sortedPost = res.data.blogInfo.sort((a, b) =>
          b.created_at
            .split("/")
            .reverse()
            .join()
            .localeCompare(a.created_at.split("/").reverse().join())
        );
        setgetdatas(sortedPost);
      })
      .catch(() => {
        alert("something went wrong");
      });
  }, [message]);

  const handleGetPoems = () => {
    axios
      .post(`${API_URL}/getAllPosts`)
      .then((res) => {
        let sortedPost = res.data.blogInfo.sort((a, b) =>
          b.created_at
            .split("/")
            .reverse()
            .join()
            .localeCompare(a.created_at.split("/").reverse().join())
        );
        setgetdatas(sortedPost);
      })
      .catch(() => {
        alert("something went wrong");
      });
  };

  const disabledDate = (current) => {
    // Disable dates in the future
    return current && current.year() > moment().year();
  };

  const handleYearChange = (date, dateString) => {
    const dateSelected = new Date(dateString);
    setSelectedYear(dateSelected.getFullYear());
  };

  const handleNextLine = () => {
    if (quillInstance) {
      const cursorPosition = quillInstance.getSelection().index || 0;
      quillInstance.insertText(cursorPosition, "\n");
    }
  };

  const handleNextLineWithSpace = () => {
    if (quillInstance) {
      const cursorPosition = quillInstance.getSelection().index || 0;
      quillInstance.insertText(cursorPosition, "\n\n");
    }
  };

  const handleTextFormatting = (format, value = null) => {
    if (quillInstance) {
      if (format === "size" && value) {
        const range = quillInstance.getSelection();
        if (range) {
          quillInstance.getEditor().format(format, value);
        }
      } else {
        // Handle other text formatting options (bold, italic, underline, etc.)
        const isActive = activeFormats.includes(format);
        let updatedFormats = [...activeFormats];

        if (isActive) {
          // Format is active, so remove it
          const index = updatedFormats.indexOf(format);
          updatedFormats.splice(index, 1);
        } else {
          // Format is not active, so add it
          updatedFormats.push(format);
        }

        quillInstance.format(format, !isActive);
        setActiveFormats(updatedFormats);
      }
    }
  };

  function change(e) {
    return;
  }
  async function onFinish(e) {
    setshow(false);
    return;
  }
  function onFinishFailed(e) {
    return;
  }

  function onFinishPoems(e) {
    const quillContent = quillInstance.getContents(); // Get the latest editor content
    const htmlContent = quillInstance.root.innerHTML; // Get the HTML content if needed
    const quillContents = quillInstance_note.getContents(); // Get the latest editor content
    const htmlContents = quillInstance_note.root.innerHTML; // Get the HTML content if needed
    const quillContentFonts = quillIntanceFont.getContents();
    const htmlContentFOnts = quillIntanceFont.root.innerHTML;
    if (quillContent && quillContent.ops[0].insert.length === 1) {
      // Handle empty quillContent, for example, display an error message
      setQuillMessage("Quote is required");
      // You can return or perform any necessary action based on the empty content
      return;
    }
    if (htmlContentFOnts.length <= 11) {
      setTitleError("Title is required");
      return;
    }
    dispatch(
      createPoems(
        {
          ...e,
          description: quillContent,
          // If you need HTML content, you can send it as well
          descriptionHTML: htmlContent,
          // year:selectedYear,
          descriptionNote: quillContents,
          descriptionHTMLNote: htmlContents,
          selectedTagIds
        },
        setsuccess,
        ids,
        setisupdate,
        isupdate,
        forimageapi,
        setIsLoading,
        quillContent,
        quillContentFonts,
        htmlContentFOnts,
        selectedColor,
        selectedCOntentColor,
        selectedTagIds
      )
    ).then((res) => {});
  }

  function onFinishFailedblog(e) {
    return;
  }
  function comes_here(e) {
    settext_value(e);
    return;
  }

  if (show == false) {
    return (
      <div className="main-wrapper">
        <Link to={"/"}>
          <div className="back-button">
            <button onClick={() => {}} className="home_button">
              <HomeOutlined style={{ color: "black" }} size={20} />
            </button>
          </div>
        </Link>
        <div className="title-head text-center">
          <h1> Create Your Own Poems</h1>
        </div>
        <div
          className="blog-wrapper_s"
          style={{
            backgroundColor: "white",
            width: "98%",
            alignSelf: "center",
            margin: "auto",
            borderRadius: "15px",
            boxShadow: "initial",
            marginBottom: 5,
          }}
        >
          <Form
            initialValues={{ remember: true }}
            name="blog_creation"
            form={form1}
            onFinish={onFinishPoems}
            // onChange={}
            onFinishFailed={onFinishFailedblog}
            layout="inline"
            style={{ width: "80%", margin: "auto", paddingTop: "20px" }}
          >
            <div className="form-group">
              <label>Title</label>
              <FormItem
                rules={[
                  {
                    required: false,
                    message: "Title is required",
                  },
                ]}
                name={`title`}
              >
                <CustomFontToolbar />
                <ReactQuill
                  theme="snow"
                  placeholder="Type your story title here..."
                  value={fontTitle_Change}
                  onChange={fontTextChange}
                  modules={modules_font}
                  formats={formats}
                  ref={(quills) => {
                    if (quills) {
                      setQuillInstanceFont(quills.getEditor());
                    }
                  }}
                />
                <label style={{ color: "red", top: 20, fontSize: 12 }}>
                  {titleError}
                </label>
              </FormItem>
            </div>
            <div className="form-group">
              <label>Image</label>
              <FormItem name={`image`}>
                <input
                  type={`file`}
                  onChange={(e) => {
                    const reader = new FileReader();
                    setforimageapi(e.target.files[0]);
                    reader.onload = function (e) {
                      // do something with the result
                      var file = reader.result || e.target.result;
                      setimage(file);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                  }}
                  accept=".jpg,.png,.jpeg"
                  placeholder="Please enter your title"
                  className="blog-inputs"
                  style={{ width: "100%" }}
                />
              </FormItem>
            </div>
            <div className="form-group">
              <label>Place</label>
              <FormItem
                rules={[
                  {
                    required: false,
                    message: "Title is required",
                  },
                ]}
                name={`place`}
              >
                <Input
                  value={place}
                  onChange={(e) => e.target.value}
                  placeholder="Enter place"
                  className="blog-inputs"
                />
              </FormItem>
            </div>
            <div className="form-group">
              <label>Year</label>
              <FormItem
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("Year is required");
                      }

                      const inputYear = parseInt(value, 10);

                      if (isNaN(inputYear)) {
                        return Promise.reject("Please enter a valid year");
                      }

                      if (inputYear > currentYear) {
                        return Promise.reject(
                          "Year cannot be greater than the current year"
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                validateFirst
                name={`year`}
              >
                <Input
                  value={selectedYear}
                  onChange={(e) => e.target.value}
                  onKeyDown={(e) => {
                    const isValidKey =
                      /^[0-9]+$/.test(e.key) ||
                      e.key === "Backspace" ||
                      e.key === "Delete" ||
                      e.key === "ArrowLeft" ||
                      e.key === "ArrowRight";

                    if (!isValidKey) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Enter year"
                  className="blog-inputs"
                />
              </FormItem>
            </div>
            <div className="form-group w-100">
              <label>Write Story</label>
              <FormItem
                rules={[
                  {
                    required: false,
                    message: "The Poem is required",
                  },
                ]}
                name={`Quote`}
              >
                <CustomToolbar />
                <ReactQuill
                  theme="snow"
                  value={text_value}
                  onChange={comes_here}
                  modules={modules}
                  formats={formats}
                  ref={(quill) => {
                    if (quill) {
                      setQuillInstance(quill.getEditor());
                    }
                  }}
                />
                <label style={{ color: "red", top: 20, fontSize: 12 }}>
                  {quillMessage}
                </label>
                <div className="sticky-toolbar">
                  <label htmlFor="background-color">
                    Choose Story content Font Color:
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 12,
                    }}
                  >
                    <select
                      id="background-color"
                      value={selectedCOntentColor}
                      onChange={(e) => handleContentCOlorChange(e.target.value)}
                    >
                      {filteredStoryColorOptions.map((colorOption) => (
                        <option
                          key={colorOption.value}
                          value={colorOption.value}
                        >
                          {colorOption.label}
                        </option>
                      ))}
                    </select>
                    <div
                      className="color-preview"
                      style={{ backgroundColor: selectedCOntentColor }}
                    ></div>
                  </div>
                </div>
                <div
                  className="text-center"
                  style={{
                    display: "flex",
                    paddingTop: 10,
                    gap: 10,
                    flexWrap: "wrap",
                    width: "80%",
                  }}
                >
                  <Button onClick={handleNextLine}>Move to Next Line</Button>
                  <Button onClick={handleNextLineWithSpace}>
                    Move to Next Para
                  </Button>
                  <Button
                    onClick={() => handleTextFormatting("bold")}
                    style={{
                      color: activeFormats.includes("bold")
                        ? "blue"
                        : "inherit",
                    }}
                  >
                    <BoldOutlined size={20} />
                  </Button>
                  <Button
                    onClick={() => handleTextFormatting("italic")}
                    style={{
                      color: activeFormats.includes("italic")
                        ? "blue"
                        : "inherit",
                    }}
                  >
                    <ItalicOutlined size={20} />
                  </Button>
                  <Button
                    onClick={() => handleTextFormatting("underline")}
                    style={{
                      color: activeFormats.includes("underline")
                        ? "blue"
                        : "inherit",
                    }}
                  >
                    <UnderlineOutlined size={20} />
                  </Button>
                </div>
              </FormItem>
            </div>

            <StickyNote
              quillInstance_note={quillInstance_note}
              setQuillInstanceNote={setQuillInstanceNote}
              inPut_note_change={inPut_note_change}
              noteText={noteText}
            />
            <div className="sticky-toolbar">
              <label htmlFor="background-color">
                Choose Note Background Color:
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 12,
                }}
              >
                <select
                  id="background-color"
                  value={selectedColor}
                  onChange={(e) => handleColorChange(e.target.value)}
                >
                  {filteredNoteColorOptions.map((colorOption) => (
                    <option key={colorOption.value} value={colorOption.value}>
                      {colorOption.label}
                    </option>
                  ))}
                </select>
                <div
                  className="color-preview"
                  style={{ backgroundColor: selectedColor }}
                ></div>
              </div>
            </div>

            <div style={{ marginLeft: "auto", width: "300px" }}>
            <label htmlFor="tags">Tag your story:</label>
            <Select
              mode="multiple"
              placeholder="Select or search tags"
              style={{ width: "100%", marginBottom: "10px" }}
              onSelect={handleTagSelect}
              onDeselect={handleTagRemove}
              allowClear
              showSearch
              value={selectedTags}
              loading={loadingTags}
            >
              {tagOptions.map((tag) => (
                <Option key={tag} value={tag}>
                  {tag}
                </Option>
              ))}
              <Option key="other" value="other">
                Other (Add custom tag)
              </Option>
            </Select>
      
            {selectedTags.includes("other") && (
              <div style={{ display: "flex", gap: "5px", marginBottom: "10px" }}>
                <Input
                  placeholder="Enter custom tag"
                  value={customTag}
                  onChange={(e) => setCustomTag(e.target.value)}
                  style={{ width: "70%" }}
                />
                <Button onClick={handleCustomTagAdd} type="primary" loading={addingTag}>
                  {addingTag ? <Spin /> : "Add"}
                </Button>
              </div>
            )}

          </div>

            <div className="form-group w-100 text-center">
              <Button
                type="primary"
                className="login_submit_button"
                htmlType="submit"
              >
                {isLoading === true ? <Spin indicator={antIcon} /> : ""}
                {isupdate == true ? `Update this poem` : "Create your poem"}
              </Button>
            </div>
          </Form>
        </div>
        {success == true ? (
          <Navigate
            to={{
              pathname: "/",
            }}
          />
        ) : (
          ""
        )}
        <div className="App">
          {getdatas.length > 0
            ? getdatas
                .filter((item) => item.pageName === pageName)
                .map((item) => {
                  let date = item.created_at.split(" ")[0].split("-");
                  const month = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ];
                  date = `${
                    date[1] > 9 ? month[date[1] - 1] : month[date[1][1] - 1]
                  }   ${date[2] > 9 ? date[2] : `0${date[2]}`} ${date[0]}`;

                  return (
                    <>
                      <div className="container-fluid post-container">
                        <div className="row">
                          <div className={`site-content `}>
                            <div
                              class={`post-sec frame1`}
                              style={{
                                backgroundColor: "#FDFAF6",
                                width: "100%",
                                borderRadius: 15,
                              }}
                            >
                              <div
                                style={{
                                  display: `flex`,
                                  justifyContent: `flex-end`,
                                }}
                              >
                                <div>
                                  <img
                                    src={editimage}
                                    id={item.id}
                                    style={{
                                      width: `20px`,
                                      marginRight: `10px`,
                                      cursor: `pointer`,
                                    }}
                                    onClick={(e) => {
                                      form1.setFieldsValue({
                                        title: item.title,
                                        Quote: item.description,
                                        year: item.year,
                                        place: item.place,
                                      });
                                      setisupdate(true);
                                      setimage(item.imageUrl);
                                      settext_value(item.description);
                                      settitle_value(item.title);
                                      setPlace(item.place);
                                      setSelectedYear(item.year);
                                      setNoteText(item.note);
                                      setSelectedColor(item.background_colour);
                                      setSelectedContentColor(
                                        item.fairytale_content_colour
                                      );
                                      setFontTitle_Change(item.title);
                                      setforimageapi(item.imageUrl);
                                      setid(e.target.id);
                                      document.body.scrollTop = 0;
                                      document.documentElement.scrollTop = 0;
                                    }}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <img
                                    src={deleteimage}
                                    style={{ cursor: `pointer`, width: `20px` }}
                                    id={item.id}
                                    onClick={(e) => {
                                      axios
                                        .post(`${API_URL}/deletePost`, {
                                          id: e.target.id,
                                        })
                                        .then((res) => {
                                          axios
                                            .post(`${API_URL}/getAllPosts`)
                                            .then((res) => {
                                              setgetdatas(res.data.blogInfo);
                                            })
                                            .catch(() => {
                                              alert("something went wrong");
                                            });
                                        });
                                      // setdeleteid(e.target.id)
                                    }}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="date-created-hidden">
                                <p>
                                  {moment(item.created_at)
                                    ?.utcOffset(0, true)
                                    .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                </p>
                              </div>
                              {(item?.pageName === "poems" &&
                                item.note !== null) ||
                              (item?.pageName === "reflections" &&
                                item.note !== null) ? (
                                <div
                                  className="note-container"
                                  style={{
                                    paddingTop: 10,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {item.title.length !== 11 ? (
                                    <h1
                                      class={`entry-title`}
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    ></h1>
                                  ) : null}
                                  {(item?.pageName === "poems" &&
                                    item.note !== null) ||
                                  (item?.pageName === "reflections" &&
                                    item.note !== null) ? (
                                    <div>
                                      {item?.pageName === "poems" ||
                                      item?.pageName === "reflections" ? (
                                        <div
                                          style={{
                                            alignSelf: "flex-start",
                                            marginBottom: 30,
                                            width: "100%",
                                          }}
                                        >
                                          <Note
                                            note={item.note}
                                            bg_color={item.background_colour}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </div>
                              ) : (
                                <div>
                                  {item.title !== null ? (
                                    <h1
                                      class={`entry-title`}
                                      // style={{height:80}}
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    ></h1>
                                  ) : null}
                                </div>
                              )}
                              <div className="date-created">
                                <p>
                                  {moment(item.created_at)
                                    ?.utcOffset(0, true)
                                    .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                </p>
                              </div>
                              {item.imageUrl ? (
                                <div
                                  style={{
                                    width: "30%",
                                    height: "20%",
                                    overflow: "hidden",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative", // Allows positioning of the delete icon
                                    flexDirection: "row",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                >
                                  <img
                                    src={item.imageUrl}
                                    alt="image"
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <Popconfirm
                                    title="Are you sure you want to delete this image?"
                                    onConfirm={() => handleDeleteImage(item)}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <DeleteOutlined
                                      style={{
                                        position: "absolute",
                                        top: "8px",
                                        right: "8px",
                                        fontSize: "16px",
                                        color: "red",
                                        cursor: "pointer",
                                        zIndex: 10, // Ensure the icon is above other elements
                                        backgroundColor:
                                          "rgba(255, 255, 255, 0.7)", // Optional: light background for better visibility
                                        borderRadius: "50%", // Makes the icon background circular
                                        padding: "4px", // Adds padding for clickable area
                                      }}
                                    />
                                  </Popconfirm>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="desc_wrapper">
                                <div
                                  style={{
                                    fontSize: `20px`,
                                    color: `${
                                      item.fairytale_content_colour
                                        ? item.fairytale_content_colour
                                        : "#000080"
                                    }`,
                                    width: "100%",
                                    display: "inline-block",
                                    whiteSpace: "pre-wrap",
                                    alignSelf: "center",
                                  }}
                                  className={`entry-content`}
                                  dangerouslySetInnerHTML={{
                                    __html: item.description.replace(
                                      /&nbsp;/g,
                                      " "
                                    ),
                                  }}
                                ></div>
                              </div>
                            </div>
                            {item?.pageName === "poems" ||
                            item?.pageName === "reflections" ? (
                              <div
                                className={`poem-reflection--create`}
                                style={{
                                  width: "93%",
                                  fontSize: `${props.parasize}px`,
                                  fontFamily: `${props.bgparaValue}`,
                                }}
                              >
                                <h>
                                  © {currentYear} All Rights Reserved.{"     "}
                                </h>
                                <h>
                                  {""}
                                  {item.place ? `${item.place},` : ""}{" "}
                                  {item.year ? item.year : ""}
                                </h>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="separator separator12"></div>
                    </>
                  );
                })
            : ""}
        </div>
      </div>
    );
  }
  return (
    <div>
      {visible ? (
        <Modal
          className="login_modal"
          title="Login"
          footer={null}
          visible={true}
          style={{ backgroundColor: "GrayText" }}
        >
          <Form
            initialValues={{ remember: false }}
            name="forget_password"
            form={form}
            onFinish={onFinish}
            onChange={change}
            onFinishFailed={onFinishFailed}
            layout="inline"
          >
            <div className="login_form">
              <label>Password</label>
              <FormItem
                rules={[
                  {
                    message: "password seems wrong",
                    validator: (_, value) => {
                      if (value == "jd@1234") {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("password not match");
                      }
                    },
                  },
                ]}
                name={`password`}
              >
                <Input
                  type={`password`}
                  placeholder="Please enter your password"
                />
              </FormItem>
            </div>

            <Button
              type="primary"
              className="login_submit_button"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form>
        </Modal>
      ) : null}
    </div>
  );
}
const mapStateToProps = (state) => ({
  poems: state.enroll.poems,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        fetchPoems,
      },
      dispatch
    ),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Poems);
