import React,{useEffect, useState} from 'react';
import { Modal,Form,Input,Button,Card } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios"
import { Navigate,useParams,useLocation, Link } from "react-router-dom";
import editimage from "./../../images/edit.png"
import { API_URL } from '../../config';
import moment from 'moment';
import { HomeOutlined } from '@ant-design/icons';
const FormItem = Form.Item
const pageName = "contact"

function Contact(props){
    const [form] = Form.useForm()
    // const {location} = props
    const [ids,setid] = useState('')
    const [form1] = Form.useForm()
    const [text_value,settext_value] = useState('')
    const [show,setshow] = useState(false)
    // const [success,setsuccess] = useState([])
    const [getdatas,setgetdatas] = useState([])
    const [title_value,settitle_value] = useState('')
    const [image,setimage] = useState('')
    const [success,setsuccess] = useState(false)
    const [isupdate,setisupdate] = useState(false)
    const [forimageapi,setforimageapi] = useState('')
    const [visible, setVisible] = useState(false);
    const [formVisible,seFormVisible] = useState(false);

    useEffect(()=>{
        axios.post(`${API_URL}/getAllPosts`).then((res)=>{
                // setsuccess(true)
                setgetdatas(res.data.blogInfo)
              }).catch(()=>{
                alert('something went wrong')
              }) 
    },[])

    function change(e){
        return
    }
    async function onFinish(e){
        setshow(false)
        return
    }
    function onFinishFailed(e){
        return
    }
    function onFinishblog(e){
        let formData = new FormData();

        const headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Methods': '*',
          }
          formData.append('title',e.title)
          formData.append('image',forimageapi)
          formData.append('Quote',e.Quote)
          formData.append('pageName',"contact")  
          if(isupdate == true){
            formData.append('id',ids)
            // formData.append('image',location.state.image)
          }else{
            
          }
          const requestOptions = {
            method: "POST",
            header: {
            //   'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
              'Access-Control-Allow-Methods': '*',
              
            },
            body: formData,
          };
          fetch(`${API_URL}/setBlogPost`, requestOptions).then(()=>{
            setsuccess(true)
            axios.post(`${API_URL}/getAllPosts`).then((res)=>{
                // setsuccess(true)
                setgetdatas(res.data.blogInfo)
              }).catch(()=>{
                alert('something went wrong')
              })
          }).catch(()=>{
            alert('something went wrong')
          });
        return
    }
    function onFinishFailedblog(e){
        return
    }
    function comes_here(e){
        settext_value(e)
        return
    }

    if(show == false){
        return (
        <div className='main-wrapper'>
          
           <Link to={'/'}><button onClick={()=>{}} className="home_button"><HomeOutlined style={{color:'black'}} size={20} /></button></Link> 

            <div className='title-head text-center'><h1> Create Your Own Contact Page</h1></div>
            <div className='blog-wrappers' style={{backgroundColor:'white',width:'95%',alignSelf:'center',margin:'auto',borderRadius:'15px',boxShadow:'initial'}}>
                <Form
                    initialValues={{remember:true}}
                    name = "blog_creation"
                    form={form1}
                    onFinish={onFinishblog}
                    // onChange={}
                    onFinishFailed={onFinishFailedblog}
                    layout="inline"
                    style={{width:'80%',margin:'auto',paddingTop:'20px'}}
                >
                    <div className='form-group'>
                        <label>Title</label>
                        <FormItem  rules={[{
                                required : true,
                                message : 'Title is required',
                            }]} name={`title`} >
                            <Input onChange={(e)=>{settitle_value(e.target.value)}}  placeholder="Please enter your title" className="blog-inputs" /> 
                        </FormItem>
                    </div>
                    <div className='form-group'>
                        <label>Profile Image</label>
                        <FormItem   name={`image`} >
                            <input type={`file`} onChange={(e)=>{
                                const reader = new FileReader();
                                setforimageapi(e.target.files[0])
                            }} accept='.jpg,.png,.jpeg'  placeholder="Please enter your title"  className="blog-inputs" style={{width: '100%'}}
                                /> 
                        </FormItem>
                    </div>
                    <div className='form-group w-100'>
                        <label>Your Bio</label>
                        <FormItem  rules={[{
                                required : true,
                                message : 'Quote is required',
                            }]} name={`Quote`} >
                            <ReactQuill theme="snow" value={text_value} onChange={comes_here} />
                        </FormItem>
                    </div>
                    <div className='form-group w-100 text-center'>
                       {getdatas.length !=0 && isupdate === true? <Button type="primary" className='login_submit_button' htmlType="submit"> Update your contact profile</Button>:null }
                       {getdatas.filter(p=> p.pageName === pageName).length ===0  && isupdate === false? <Button type="primary" className='login_submit_button' htmlType="submit"> Create your contact profile</Button>:null }
                    </div>
                    {/* <div class="separator separator12"></div> */}
                       
                </Form>
            </div>
            {success == true ? (
          <Navigate
            to={{
              pathname: "/",
            }}
          />
        ) : ''}
            <div className="App"> 
            {getdatas.length > 0 ? getdatas.filter(item => item.pageName === "contact").map((item)=>{
                let date = item.created_at.split(' ')[0].split('-')
                const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                date = `${date[1] > 9 ? month[date[1]- 1]  : month[date[1] [1] - 1]}   ${date[2] > 9 ? date[2] : `0${date[2]}`} ${date[0]}`
                
        return (
          <>
          <div className="container-fluid post-container" > 
            <div class={`post-sec frame1`}>
              <div style={{display:`flex`,justifyContent:`flex-end`}}>
                <div>
            <img src={editimage} id={item.id} style={{width:`20px`,marginRight:`10px`,cursor:`pointer`}} onClick={((e)=>{
                form1.setFieldsValue({
                    title : item.title,
                    Quote: item.description,
                })
                setisupdate(true)           
                setforimageapi(item.imageUrl)
                setid(e.target.id)
                document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
                      })} /></div>
            </div>
              <div className="date-created-hidden"><p>{moment(item.created_at)?.utcOffset(0, true).format('dddd, MMMM Do YYYY, h:mm:ss a')}</p></div> 
              <h1 class="entry-title">{item.title}</h1>
              <div className="date-created"><p>{moment(item.created_at)?.utcOffset(0, true).format('dddd, MMMM Do YYYY, h:mm:ss a')}</p></div> 
              <div className="UserProfileCard">
               {item.imageUrl ? <img src={item.imageUrl} alt="Profile" className='img' style={{ width: "250px",
                    height: "250px",
                    objectFit: "cover",
                    borderRadius:'50%',
                    marginTop:'-40px'
                    }} />
                    : ''}
            </div>
            <div className="desc_wrapper">
              <div style={{ fontSize: `28px`,width:'100%', display: 'inline-block', wordWrap: 'break-word'}} class={`entry-content `} dangerouslySetInnerHTML={{ __html: item.description }}>

              </div>
            </div>
            </div>
      </div>
      </>
      )
      })  : ''}
            
            
            </div>
        </div>
        )
    }
return (
  <div>
   {visible? 
   <Modal className='login_modal' title="Login" footer={null} visible={true} style={{backgroundColor:'GrayText'}}>
        <Form
            initialValues={{remember:true}}
            name = "forget_password"
            form={form}
            onFinish={onFinish}
            onChange={change}
            onFinishFailed={onFinishFailed}
            layout="inline"
        >
            <div className='login_form'>
                <label>Password</label>
                <FormItem  rules={[{
                        message : 'password seems wrong',
                        validator : (_,value)=>{
                            if(value == 'jd@1234'){
                                return Promise.resolve();
                            }else{
                                return Promise.reject('password not match')
                            }
                        }
                    }]} name={`password`} >
                    <Input type={`password`} placeholder="Please enter your password" /> 
                </FormItem>
            </div>

            <Button type="primary" className='login_submit_button' htmlType="submit">Submit</Button>

        </Form>
  </Modal>:null}
  </div>
    )
}

export default Contact
