import defaultProfile from "../../images/defaultProfile.jpg";
import fairtyle from "../../images/winter-landscape.jpg";
const fontColors =[
    {name: 'black', value: '#000000'},
    {name: 'Inky Blue', value: '#000080'},
   
]

export default fontColors;

let date = new Date();
export const authorInfo = [
    {
        title: 'Saurabh Kumar',
        bio: 'Saurabh Kumar is a Diné writer and filmmaker originally from Tohatchi, New Mexico. He has a bachelor’s',
        created_at:"2023-01-21 03:26:58",
        updated_at : "2023-01-21 03:26:58",
    }
]


export const fairyTaleStory = [
    {
        title: 'The Little Mermaid',
        imageUrl:fairtyle,
        story: 'The Little Mermaid is a fairy tale written by the Danish author Hans Christian Andersen. The story follows the journey of a young mermaid who is willing to give up her life in the sea as a mermaid to gain a human soul. The tale was first published in 1837 as part of a collection of fairy tales for children.',
        created_at:"2023-01-21 03:26:58",
        updated_at : "2023-01-21 03:26:58",
    },
    {
        title: 'The Little Mermaid',
        imageUrl: fairtyle,
        story: 'The Little Mermaid is a fairy tale written by the Danish author Hans Christian Andersen. The story follows the journey of a young mermaid who is willing to give up her life in the sea as a mermaid to gain a human soul. The tale was first published in 1837 as part of a collection of fairy tales for children.',
        created_at:"2023-01-21 03:26:58",
        updated_at : "2023-01-21 03:26:58",
    },
]

export const aboutMe = [
    {
        title: 'About Me',
        profilepic: defaultProfile,
        story: 'The Little Mermaid is a fairy tale written by the Danish author Hans Christian Andersen. The story follows the journey of a young mermaid who is willing to give up her life in the sea as a mermaid to gain a human soul. The tale was first published in 1837 as part of a collection of fairy tales for children.',
        created_at:"2023-01-21 03:26:58",
        updated_at : "2023-01-21 03:26:58",
    },
]

export const fontStylesMap = {
    Quickkisses: "'Quickkisses",
    Tomatoes: "Tomatoes",
    WhiteChocolate: "whitechocolate",
    MarshMallow: "MarshMallow",
    FallisComing: "FallisComing",
    Autumn: "Autumn",
    BetterGrade: "BetterGrade",
    FaeryTalewood: "FaeryTalewood",
    SpringSakura: "SpringSakura",
    Amesterdam: "Amesterdam",
    Georgia: "Georgia",
    Verdana: "Verdana",
    Timesnewroman: "Timesnewroman",
    AntiqueFOnt: "AntiqueFOnt",
    RingletBlack: "RingletBlack",
  };