import * as types from '../Actions/actionTypes';

const initialState = {
    blogs: [],
    fairytales: [],
    poems:[],
    reflections:[],
    tagsList:[],
    loading: false,
    error: null
}

export const enroll = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_BLOGS_LIST:
            return {
                ...state,
                blogs: action.payload,
                loading: false
            }
        case types.FETCH_FAIRYTALES_LIST:
            return {
                ...state,
                fairytales: action.payload,
                loading: false
            }
        case types.FETCH_POEMS_LIST:
            return {
                ...state,
                poems: action.payload,
                loading: false
            }
        case types.FETCH_REFLECTIONS_LIST:
            return {
                ...state,
                reflections: action.payload,
                loading: false
            }
        case types.ADD_FAIRYTALES:
            return {
                ...state,
                fairytales: action.payload,
                loading: false
            }
        case types.ADD_POEMS:
            return {
                ...state,
                poems: action.payload,
                loading: false
            }
        case types.ADD_TAGS_LIST:
            return {
                ...state,
                tagsList: action.payload,
                loading: false
            }
        case types.ADD_BLOGS:
            return {
                ...state,
                blogs: action.payload,
                loading: false
            }
        case types.ADD_REFLECTIONS:
            return {
                ...state,
                reflections: action.payload,
                loading: false
            }
        case types.FETCH_TAGS_LIST:
            return {
                ...state,
                tagsList: action.payload,
                loading: false
            }
        case types.ADD_ABOUT_ME:
            return {
                ...state,
                payload: action.payload,
                loading: false
            }
        case types.DELETE_POEMS:
            return {
                ...state,
                payload: action.payload,
                loading: false
            }
        case types.SEND_CONTACT_MESSAGE:
            return {
                ...state,
                payload: action.payload,
                loading: false
            }
    
       default:
            return state;

        }
}