import { createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../Reducers/index';


const loggerMiddleware = createLogger({collapsed:true});
const composeEnhancers = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
let middlewareList = [];
if (process.env.NODE_ENV === 'development') {
    middlewareList = [ thunk, loggerMiddleware];  
  } else {
    middlewareList = [ thunk];
  }
const enhancer = composeEnhancers(applyMiddleware(...middlewareList));

const store = createStore(rootReducer, enhancer);

// set up a store subscription listener
// to store the users token in localStorage

// initialize current state from redux store for subscription comparison
// preventing undefined error
let currentState = store.getState();
store.subscribe(() => {
  // keep track of the previous and current state to compare changes
  let previousState = currentState;
  currentState = store.getState();


  // if the token changes set the value in localStorage and axios headers
//   if (previousState.auth.token !== currentState.auth.token) {
//     const token = currentState.auth.token;
//     setAuthToken(token);
//   }
});
export default store;