import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom"; 
import "./App.css";

function DemoSeven(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsModalVisible(true); // Show modal when the component mounts
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
    props.demopage("DemoSeven")
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="container-fluid post-container">
      <div className="row">
        <div className={`site-content }`}>
          <div className={`post-sec frame1 ${props.bgValue}`}>
            <div className="App">
            <div className="coming-soon-container">
                <label className="coming-soon-message">Coming soon!</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  poems: state.enroll.poems,
});

export default connect(mapStateToProps)(DemoSeven);
