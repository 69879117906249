import { LoadingOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
export const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "#fff",right:3
    }}
    spin
  />
);

export const deleteLoadIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "black",right:3
    }}
    spin
  />
);
export const unLockIcon = (
  <UnlockOutlined
  style={{
    fontSize: 17,
    color: "black",right:3
  }}
  />
)

export const lockIcon = (
  <LockOutlined 
  style={{
    fontSize: 17,
    color: "black",right:3
  }}
  />
)
