import React, { useEffect, useState } from "react";
import "./App.css";
import { connect, useDispatch } from "react-redux";
import { getFairyTale } from "./Actions/fairytale";
import ContentBlogLoader from "./Components/Loader/contentLoader";
import BodyBlogLoader from "./Components/Loader/bodyLoader";
import images_nodata from "../src/images/new-menu-bg/images_nodata.png";

function DemoOne({
  parasize,
  font_type,
  bgValue,
  bgparaValue,
  fairytales,
}) {
  const [success, setsuccess] = useState([]);
  const [getdatas, setgetdatas] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFairyTale(setsuccess));
  }, []);

  const antiqueFontStyle = {
    fontSize: 23,
  };

  useEffect(() => {
    if (fairytales?.length !== 0) {
      setgetdatas(fairytales);
      setsuccess(true);
    }
  }, [fairytales]);

  if (success == false) {
    return (
      <div>
        <div className="contentLoader">
          <ContentBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
      </div>
    );
  }
  return (
    <div className="App">
      {success == true
        ? getdatas
            .filter((item) => item.pageName === "fairytale")
            .map((item, index) => {
              {
              }
              return (
                <div>
                  <div className="container-fluid post-container">
                    <div className="row">
                      <div className={`site-content ${font_type}`}>
                        <div class={` post-sec frame1 ${bgValue}`}>
                          {index === 0 ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <span
                                className="font-fairy-god"
                                style={{
                                  fontSize: 35,
                                  color: `rgb(15, 48, 122)`,
                                  fontWeight: "bold",
                                }}
                              >
                                Fairytale
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="fairly-main">
                            {item.imageUrl ? (
                              <img
                                className={`image1`}
                                src={item.imageUrl}
                                alt=""
                              ></img>
                            ) : (
                              ""
                            )}
                            <div
                              className="desc_wrapper_fairytale"
                            >
                            <div
                            style={{
                              fontSize: `${parasize}px`,
                              color: `${item.fairytale_content_colour? item.fairytale_content_colour:'#0F307A'}`,
                              width: "100%",
                              display: "inline-block",
                              wordWrap: "break-word",
                              lineHeight: "0.2em",
                              paddingTop: 40,
                              paddingBottom: 2,
                              fontFamily: 'fairygod'
                            }}
                            class={`entry-contents ${bgparaValue}`}
                            dangerouslySetInnerHTML={{
                              __html: `<p  class="story-fairy" style="margin-left: -5px;">${item.description
                                .slice(22)
                                .slice(1)}</span></p>`,
                            }}
                          ></div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="separator"></div>
                </div>
              );
            })
        : ""}
      {getdatas.length === 0 ? (
        <div
          className=""
          style={{
            alignSelf: "center",
            marginTop: 100,
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src={images_nodata}
            alt=""
            width={100}
            height={100}
            style={{ margin: "auto" }}
          />
          <label
            style={{
              alignSelf: "center",
              marginTop: 30,
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            No Fairytale found!
          </label>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  fairytales: state.enroll.fairytales,
});
export default connect(mapStateToProps)(DemoOne);
