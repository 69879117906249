import axios from "axios";
import { API_URL } from "../config";
import api from "../utils/api";
import { ADD_BLOGS, FETCH_BLOGS_LIST } from "./actionTypes";

export const getBlogs = (setsuccess) => async dispatch => {
    try {
      const res = await axios.post(`${API_URL}/getAllPosts`);
      const sorted = res.data.blogInfo.sort((a, b) =>
                  b.created_at.split('/').reverse().join().localeCompare(a.created_at.split('/').reverse().join())
                );           
      dispatch({
        type: FETCH_BLOGS_LIST,
        payload: sorted
      });
      setsuccess(true)
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  };


  export const createBlogs = (e,setsuccess,ids,setisupdate,isupdate,forimageapi,setIsLoading,pageName,setDeleteLoader,text_value,quillContent,htmlContents,quillContents,html,quillContentFonts,
    htmlContentFOnts,selectedColor) => async dispatch => {
    setIsLoading(true)
    try {
      const encodedContent = e.descriptionHTML.replace(/ /g, '&nbsp;');
      let formData = new FormData();
          formData.append('title',htmlContentFOnts.length === 11 ? "" : htmlContentFOnts);
          formData.append('image',forimageapi)
          formData.append('place',typeof e.place === 'undefined'? "":e.place === null?"":  e.place)
          formData.append('year',e.year === null ? "":isNaN(e.year)?"": e.year)
          // formData.append('year',"")
          formData.append('background_colour',selectedColor)
          formData.append('Quote',pageName === "fairytale" ? html : encodedContent)  
          formData.append('note',htmlContents.length === 11 ? "":htmlContents)
          if(pageName !== "blog" ) formData.append('pageName', pageName)
          if(pageName === "blog" ) formData.append('pageName',pageName) 
        if(isupdate == true){
          formData.append('id',ids)
          setDeleteLoader(true)
        }else{
          
        }
   
    const res = await axios.post(`${API_URL}/setBlogPost`, formData);
      dispatch({
        type: ADD_BLOGS,
        payload: res.data.blogInfo
      });
      setsuccess(true)
      setIsLoading(false)
      setDeleteLoader(false)
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  };
  