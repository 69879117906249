import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Spin } from "antd";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention";
import 'quill/modules/keyboard';
import axios from "axios";
import { Navigate, useParams, useLocation, Link } from "react-router-dom";
import editimage from "./../../images/edit.png";
import deleteimage from "./../../images/delete.png";
import moment from "moment";
import { API_URL } from "../../config";
import { useDispatch } from "react-redux";
import { createFairyTale } from "../../Actions/fairytale";
import { antIcon } from "../Loader/antIcon";
import {
  BoldOutlined,
  HomeOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  UnlockOutlined,
} from "@ant-design/icons";

const FormItem = Form.Item;
const pageName = "fairytale";

const html = `
  <p>Once upon a time a fairy said</p>
  <p><br/></p>
  <p><br/></p>
  <p>THE END</p>
</p>
`;

const Font = Quill.import("formats/font");
const FontSecond = Quill.import("formats/font");
// We do not add Aref Ruqaa since it is the default
Font.whitelist = [
  "madeEvolve",
  "dutchTulips",
  "quickgram",
  "crackerBanana",
  "nursery",
];
FontSecond.whitelist = [
  "madeEvolve",
  "dutchTulips",
  "quickgram",
  "crackerBanana",
  "nursery",
  "tuckers",
  "winterLove",
  "alishader",
  "serendipity",
  "bluehill",
  "bunchOfFlowers",
  "christmasInk",
  "fairygod",
  "misteriosDelAmor",
  "soybeanut",
  "spaceCraft",
  "helloPatty",
  "jathafaDemo",
];
Quill.register(Font, true);
Quill.register(FontSecond, true);

var Size = Quill.import("formats/size");
Size.whitelist = [
  "4px",
  "5px",
  "6px",
  "7px",
  "8px",
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];

Quill.register(Size, true);

const Parchment = Quill.import("parchment");
const boxAttributor = new Parchment.Attributor.Class("box", "line", {
  scope: Parchment.Scope.INLINE,
  whitelist: ["solid", "double", "dotted"],
});
Quill.register(boxAttributor);

const atValues = [
  { id: 0, value: "barcode" },
  { id: 1, value: "customername" },
  { id: 2, value: "licensenumber" },
  { id: 3, value: "netweight" },
  { id: 4, value: "packageid" },
  { id: 5, value: "price" },
  { id: 6, value: "productname" },
  { id: 7, value: "supplierid" },
];

const excludedFonts = [
  "tuckers",
  "winterLove",
  "alishader",
  "serendipity",
  "bluehill",
  "bunchOfFlowers",
  "christmasInk",
  "misteriosDelAmor",
  "soybeanut",
  "spaceCraft",
  "helloPatty",
  "jathafaDemo"
];

// Filter the fonts to exclude those in the excludedFonts array
const filteredFonts = Font.whitelist.filter(
  (font) => !excludedFonts.includes(font)
);

const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-indent" value="+1" />
    <button className="ql-underline" />
    <button className="ql-italic" />
    <select className="ql-font">
      {filteredFonts.map((font, index) => (
        <option
          key={index}
          value={font}
          defaultValue={font.includes("verdana")}
        >
          {font.length > 10 ? `${font.substr(0, 11)}..` : font[0].toUpperCase() + font.substr(1)}
        </option>
      ))}
    </select>
    <select className="ql-size">
      {Size.whitelist.map((size, index) => {
        const sizeWithoutPx = size.replace("px", "");
        return (
          <option value={size} selected={size.includes("12")}>
            {sizeWithoutPx}
          </option>
        );
      })}
    </select>
  </div>
);

const customBindings = {
  space: {
    key: ' ',
    handler: function(range, context) {
      // Insert a space at the current cursor position
      this.quill.insertText(range.index, ' ');

      // Update the cursor position
      this.quill.setSelection(range.index + 1);
    },
  },
};

Fairytale.modules = {
  toolbar: {
    container: "#toolbar",
    keyboard: {
      bindings: customBindings,
    },
  },
  keyboard: {
    bindings: Quill.import('modules/keyboard').bindings,
  },
};

Fairytale.formats = [
  "bold",
  "underline",
  "italic",
  "font",
  "size",
  "indent",
  "box",
  // "mention",
];

function Fairytale(props) {
  const [form] = Form.useForm();
  // const {location} = props
  const [ids, setid] = useState("");
  const [form1] = Form.useForm();
  const [text_value, settext_value] = useState("");
  const [show, setshow] = useState(false);
  const [getdatas, setgetdatas] = useState([]);
  const [title_value, settitle_value] = useState("");
  const [image, setimage] = useState("");
  const [success, setsuccess] = useState(false);
  const [isupdate, setisupdate] = useState(false);
  const [forimageapi, setforimageapi] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quillInstance, setQuillInstance] = useState(null);
  const [activeFormats, setActiveFormats] = useState([]);
  const [quillMessage, setQuillMessage] = useState("");

  const [selectedColor, setSelectedColor] = useState("#000080"); // Default color

  // Add more colors if needed
  const colorOptions = [
    { label: "inky blue", value: "#000080" },
    { label: "black", value: "#000" },
    {label: "NewBlue", value: "#0F307A" },
  ];

  // Handle color change
  const handleColorChange = (color) => {
    setSelectedColor(color);
    // You can also save the color to the database here if needed
  };

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .post(`${API_URL}/getAllPosts`)
      .then((res) => {
        // setsuccess(true)
        let sortedPost = res.data.blogInfo.sort((a, b) =>
          b.created_at
            .split("/")
            .reverse()
            .join()
            .localeCompare(a.created_at.split("/").reverse().join())
        );
        setgetdatas(sortedPost);
      })
      .catch(() => {
        alert("something went wrong");
      });
  }, []);

  const handleNextLine = () => {
    if (quillInstance) {
      const cursorPosition = quillInstance.getSelection().index || 0;
      quillInstance.insertText(cursorPosition, "\n");
    }
  };

  const handleNextLineWithSpace = () => {
    if (quillInstance) {
      const cursorPosition = quillInstance.getSelection().index || 0;
      quillInstance.insertText(cursorPosition, "\n\n");
    }
  };

  const handleTextFormatting = (format, value = null) => {
    if (quillInstance) {
      if (format === "size" && value) {
        const range = quillInstance.getSelection();
        if (range) {
          quillInstance.getEditor().format(format, value);
        }
      } else {
        // Handle other text formatting options (bold, italic, underline, etc.)
        const isActive = activeFormats.includes(format);
        let updatedFormats = [...activeFormats];

        if (isActive) {
          // Format is active, so remove it
          const index = updatedFormats.indexOf(format);
          updatedFormats.splice(index, 1);
        } else {
          // Format is not active, so add it
          updatedFormats.push(format);
        }

        quillInstance.format(format, !isActive);
        setActiveFormats(updatedFormats);
      }
    }
  };

  function change(e) {
    return;
  }
  async function onFinish(e) {
    setshow(false);
    return;
  }
  function onFinishFailed(e) {
    return;
  }

  function onFinishFairyTale(e) {
    const quillContent = quillInstance.getContents(); // Get the latest editor content
    const htmlContent = quillInstance.root.innerHTML; // Get the HTML content if needed
    if (quillContent && quillContent.ops[0].insert.length === 1) {
      // Handle empty quillContent, for example, display an error message
      setQuillMessage("Quote is required");
      // You can return or perform any necessary action based on the empty content
      return;
    }
    const html = `
    <p class="story-fairy">Once upon a time,</p>
      ${htmlContent}
    <p><span class="story-fairys" style="font-family: 'lyricpoetry';margin-top: 20px;">The End</span></p>
  `;
    dispatch(
      createFairyTale(
        {
          ...e,
          description: quillContent,
          // If you need HTML content, you can send it as well
          descriptionHTML: htmlContent,
        },
        setsuccess,
        ids,
        setisupdate,
        isupdate,
        forimageapi,
        setIsLoading,
        selectedColor,
        html,
        quillContent,
      )
    ).then((res) => {
    });
  }

  function onFinishFailedblog(e) {
    return;
  }
  function comes_here(e) {
    settext_value(e);
    return;
  }
  if (show == false) {
    return (
      <div className="main-wrapper">
        <Link to={"/"}>
          <div className="back-button">
            <button onClick={() => {}} className="home_button">
              <HomeOutlined style={{ color: "black" }} size={20} />
            </button>
          </div>
        </Link>

        <div className="title-head text-center">
          <h1> Create Your Own Fairy Tale</h1>
        </div>
        <div
          className="blog-wrappers"
          style={{
            backgroundColor: "white",
            width: "98%",
            alignSelf: "center",
            margin: "auto",
            borderRadius: "15px",
            boxShadow: "initial",
            marginBottom: 5,
          }}
        >
          <Form
            initialValues={{ remember: true }}
            name="blog_creation"
            form={form1}
            onFinish={onFinishFairyTale}
            // onChange={}
            onFinishFailed={onFinishFailedblog}
            layout="inline"
            style={{ width: "80%", margin: "auto", paddingTop: "20px" }}
          >
            <div className="form-group">
              <label>Image</label>
              <FormItem name={`image`}>
                <input
                  type={`file`}
                  onChange={(e) => {
                    const reader = new FileReader();
                    setforimageapi(e.target.files[0]);
                    reader.onload = function (e) {
                      // do something with the result
                      var file = reader.result || e.target.result;
                      setimage(file);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                  }}
                  accept=".jpg,.png,.jpeg"
                  placeholder="Please enter your title"
                  className="blog-inputs"
                  style={{ width: "100%" }}
                />
              </FormItem>
            </div>
            <div className="form-group w-100">
              <label>Write Story</label>
              <FormItem
                rules={[
                  {
                    required: false,
                    message: "The Story is required",
                  },
                ]}
                name={`Quote`}
              >
                <CustomToolbar />
                <ReactQuill
                  theme="snow"
                  value={text_value}
                  onChange={comes_here}
                  modules={Fairytale.modules}
                  formats={Fairytale.formats}
                  ref={(quill) => {
                    if (quill) {
                      setQuillInstance(quill.getEditor());
                    }
                  }}
                />
                <label style={{ color: "red", top: 20, fontSize: 12 }}>
                  {quillMessage}
                </label>
                <div className="sticky-toolbar">
                <label htmlFor="background-color">
                  Choose Story content Text Color:
                </label>
                <div style={{display:'flex',flexDirection:'row',marginBottom:12}}>
                <select
                  id="background-color"
                  value={selectedColor}
                  onChange={(e) => handleColorChange(e.target.value)}
                >
                  {colorOptions.map((colorOption) => (
                    <option key={colorOption.value} value={colorOption.value}>
                      {colorOption.label}
                    </option>
                  ))}
                </select>
                <div
                  className="color-preview"
                  style={{ backgroundColor: selectedColor }}
                ></div>
                </div>
              </div>
                <div
                  className="text-center"
                  style={{
                    display: "flex",
                    paddingTop: 10,
                    gap: 10,
                    flexWrap: "wrap",
                    width: "80%",
                  }}
                >
                  <Button onClick={handleNextLine}>Move to Next Line</Button>
                  <Button onClick={handleNextLineWithSpace}>
                    Move to Next Para
                  </Button>
                  <Button
                    onClick={() => handleTextFormatting("bold")}
                    style={{
                      color: activeFormats.includes("bold")
                        ? "blue"
                        : "inherit",
                    }}
                  >
                    <BoldOutlined size={20} />
                  </Button>
                  <Button
                    onClick={() => handleTextFormatting("italic")}
                    style={{
                      color: activeFormats.includes("italic")
                        ? "blue"
                        : "inherit",
                    }}
                  >
                    <ItalicOutlined size={20} />
                  </Button>
                  <Button
                    onClick={() => handleTextFormatting("underline")}
                    style={{
                      color: activeFormats.includes("underline")
                        ? "blue"
                        : "inherit",
                    }}
                  >
                    <UnderlineOutlined size={20} />
                  </Button>
                </div>
              </FormItem>
            </div>
            <div className="form-group w-100 text-center">
              <Button
                type="primary"
                className="login_submit_button"
                htmlType="submit"
              >
                {isLoading === true ? <Spin indicator={antIcon} /> : ""}
                {isupdate == true
                  ? `Update this fairytale story`
                  : "Create your fairytale story"}
              </Button>
            </div>
            {/* <div class="separator separator12"></div> */}
          </Form>
        </div>
        {success == true ? (
          <Navigate
            to={{
              pathname: "/",
            }}
          />
        ) : (
          ""
        )}
        <div className="App">
          {getdatas.length > 0
            ? getdatas
                .filter((item) => item.pageName === pageName)
                .map((item) => {
                  let date = item.created_at.split(" ")[0].split("-");
                  const month = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ];
                  date = `${
                    date[1] > 9 ? month[date[1] - 1] : month[date[1][1] - 1]
                  }   ${date[2] > 9 ? date[2] : `0${date[2]}`} ${date[0]}`;
                  return (
                    <React.Fragment>
                      <div className="container-fluid post-container">
                        <div className="row">
                          <div className={`site-content `}>
                            <div
                              class={`post-sec frame1`}
                              style={{
                                backgroundColor: "#FDFAF6",
                                width: "100%",
                                borderRadius: 15,
                              }}
                            >
                              <div
                                style={{
                                  display: `flex`,
                                  justifyContent: `flex-end`,
                                }}
                              >
                                <div>
                                  <img
                                    src={editimage}
                                    id={item.id}
                                    style={{
                                      width: `20px`,
                                      marginRight: `10px`,
                                      cursor: `pointer`,
                                    }}
                                    onClick={(e) => {
                                      const editedDescription = item.description
                                        .split(/\r?\n|\r|\n/g)
                                        .slice(1)
                                        .slice(0, -1)[0];
                                      form1.setFieldsValue({
                                        title: item.title,
                                        Quote: item.description
                                          .split(/\r?\n|\r|\n/g)
                                          .slice(1)
                                          .slice(0, -1)[0],
                                      });
                                      setisupdate(true);
                                      setimage(item.imageUrl);
                                      settext_value(editedDescription);
                                      settitle_value(item.title);
                                      setforimageapi(item.imageUrl);
                                      setid(e.target.id);
                                      document.body.scrollTop = 0;
                                      document.documentElement.scrollTop = 0;
                                    }}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <img
                                    src={deleteimage}
                                    style={{ cursor: `pointer`, width: `20px` }}
                                    id={item.id}
                                    onClick={(e) => {
                                      axios
                                        .post(`${API_URL}/deletePost`, {
                                          id: e.target.id,
                                        })
                                        .then((res) => {
                                          axios
                                            .post(`${API_URL}/getAllPosts`)
                                            .then((res) => {
                                              setgetdatas(res.data.blogInfo);
                                            })
                                            .catch(() => {
                                              alert("something went wrong");
                                            });
                                        });
                                      // setdeleteid(e.target.id)
                                    }}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="date-created-hidden">
                                <p>
                                  {moment(item.created_at)
                                    ?.utcOffset(0, true)
                                    .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                </p>
                              </div>
                              <h1 class="entry-title">{item.title}</h1>
                              <div className="date-created">
                                <p>
                                  {moment(item.created_at)
                                    ?.utcOffset(0, true)
                                    .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                </p>
                              </div>
                            
                          <div className="fairly-main">
                          {item.imageUrl ? (
                            <img
                              className={`image1`}
                              src={item.imageUrl}
                              style={{ width: "98%" }}
                              alt=""
                            ></img>
                          ) : (
                            ""
                          )}
                              <div
                              className="desc_wrapper_fairytale"
                            >
                            <div
                            style={{
                              fontSize: `${props.parasize}px`,
                              color: `${item.fairytale_content_colour? item.fairytale_content_colour:'#000080'}`,
                              width: "100%",
                              display: "inline-block",
                              wordWrap: "break-word",
                              lineHeight: 1,
                              paddingTop: 40,
                              paddingBottom: 2,
                              fontFamily: 'fairygod'
                            }}
                            class={`entry-contents ${props.bgparaValue}`}
                            dangerouslySetInnerHTML={{
                              __html: `<p  class="story-fairy" style="margin-left: -5px;">${item.description
                                .slice(22)
                                .slice(1)}</span></p>`,
                            }}
                          ></div> 
                            </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="separator separator12"></div>
                    </React.Fragment>
                  );
                })
            : ""}
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      {visible ? (
        <Modal
          className="login_modal"
          title="Login"
          footer={null}
          visible={true}
          style={{ backgroundColor: "GrayText" }}
        >
          <Form
            initialValues={{ remember: false }}
            name="forget_password"
            form={form}
            onFinish={onFinish}
            onChange={change}
            onFinishFailed={onFinishFailed}
            layout="inline"
          >
            <div className="login_form">
              <label>Password</label>
              <FormItem
                rules={[
                  {
                    message: "password seems wrong",
                    validator: (_, value) => {
                      if (value == "jd@1234") {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("password not match");
                      }
                    },
                  },
                ]}
                name={`password`}
              >
                <Input
                  type={`password`}
                  placeholder="Please enter your password"
                />
              </FormItem>
            </div>

            <Button
              type="primary"
              className="login_submit_button"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form>
        </Modal>
      ) : null}
    </React.Fragment>
  );
}

export default Fairytale;
