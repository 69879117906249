import axios from "axios";
import { API_URL } from "../config";

import { ADD_FAIRYTALES, FETCH_FAIRYTALES_LIST } from "./actionTypes";

export const getFairyTale = (setsuccess) => async dispatch => {
  let pageName = "fairytale"
    try {
      const res = await axios.post(`${API_URL}/getAllPosts`);
    let sortedFairyTale = res.data.blogInfo.filter(item => item.pageName === pageName).sort((a, b) => b.created_at.split('/').reverse().join().localeCompare(a.created_at.split('/').reverse().join()));

      dispatch({
        type: FETCH_FAIRYTALES_LIST,
        payload: sortedFairyTale
      });
      setsuccess(true)
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  };


  export const createFairyTale = (e,setsuccess,ids,setisupdate,isupdate,forimageapi,setIsLoading,selectedColor,html) => async dispatch => {
    setIsLoading(true)
    try {
      const encodedContent = html.replace(/ /g, '&nbsp;');
      let formData = new FormData();
          formData.append('title',"")
          formData.append('image',forimageapi)
          formData.append('Quote',html)  
          formData.append('place','')
          formData.append('year','')
          formData.append('note','')
          formData.append('fairytale_content_colour',selectedColor)
          formData.append('background_colour',"")
          formData.append('pageName',"fairytale")
        if(isupdate == true){
          formData.append('id',ids)
        }else{
          
        }
   
    const res = await axios.post(`${API_URL}/setBlogPost`, formData);

      dispatch({
        type: ADD_FAIRYTALES,
        payload: res.data.blogInfo
      });
      setsuccess(true)
      setIsLoading(false)
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  }
