import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import { Modal,Form,Input,Button,Spin,message,Checkbox } from 'antd';
import axios from "axios"
import { sendMessage } from "./Actions/contactus";
import { useDispatch } from "react-redux";
import { antIcon } from "./Components/Loader/antIcon";
const FormItem = Form.Item


function DemoFive({parasize, menuvalue, font_type, bgValue, bgparaValue,}) {
  const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [text_value,settext_value] = useState('')
    const [show,setshow] = useState(false)
    const [name_value,setName_value] = useState('')
    const [email_value,setEmail_value] = useState('')
    const [subject_value,setSubject_value] = useState('')
    const [message_value,setMessage_value] = useState('')
    const [success,setsuccess] = useState(false)
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [checkUpdate,setCheckUpdate] = useState(true);
    const [checkUpdateValue,setCheckUpdateValue] = useState(1);
    const dispatch =useDispatch()
    const [messageApi, contextHolder] = message.useMessage();


    const successPopup = () => {
      Modal.success({
        title: 'Success',
        content: 'Thank you for contacting me!',
        width: '600px', // Customize width as needed
        centered: true, // Center the modal on the screen
        onOk() {}, // You can customize the onOk function if needed
        okButtonProps: { style: { width: '100px' } }, // Customize OK button width
      });
    };
    
    const onChangeCheckBox = (e) => {
        setCheckUpdate(!checkUpdate)
        if(e.target.checked === true){
            setCheckUpdateValue(1)
        }else{
            setCheckUpdateValue(0)
        }
    }; 


    const [ip,setIP] = useState('');
    
    //creating function to load ip address from the API
    const getData = async()=>{
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }
    
    useEffect(()=>{
        //passing getData method to the lifecycle method
        getData()
    },[])
  
    function change(e){
        return
    }
    async function onFinish(e){
        setshow(false)
        return
    }
    function onFinishFailed(e){
        return
    }
    function onFinishblog(e){
      setLoading(true)
      dispatch(sendMessage(e,successPopup,setLoading,setsuccess,ip,checkUpdateValue)).then((res)=>{ 

        setLoading(false)
        form1.resetFields()
      }) 
    }
    function onFinishFailedblog(e){
        return
    }
    
    if(show == false){
        return (
        <div className='main-wrapper main-warrper-small'>
        <div className='title-head text-center' style={{fontFamily:'madeEvolve',color: 'rgb(15, 48, 122)',padding: 20}}><h1> Contact Us</h1></div>
            <div className='blog-wrappers' style={{backgroundColor:'white',width:'95%',alignSelf:'center',margin:'auto',borderRadius:'15px',boxShadow:'initial'}}>
            {contextHolder}
                <Form
                    initialValues={{remember:true}}
                    name = "blog_creation"
                    form={form1}
                    onFinish={onFinishblog}
                    // onChange={}
                    onFinishFailed={onFinishFailedblog}
                    layout="inline"
                    style={{width:'80%',margin:'auto',paddingTop:'20px'}}
                >
                  <div className="emlandname">
                     <div className='form-group  w-100'>
                        <label style={{fontFamily:'madeEvolve',color: 'rgb(15, 48, 122)'}}>Name</label>
                        <FormItem  rules={[{
                                required : true,
                                message : 'Name is required',
                            }]} name={`name`} >
                            <Input onChange={(e)=>{setName_value(e.target.value)}}  placeholder="Please enter your title" className="blog-inputs" style={{width:'100%',borderRadius:5,borderColor:'gray',borderWidth:0.5,fontFamily:'madeEvolve'}} /> 
                        </FormItem>
                    </div>
                    <div className='form-group  w-100'>
                        <label style={{fontFamily:'madeEvolve', color: 'rgb(15, 48, 122)'}}>Email</label>
                        <FormItem   name={`email`} rules={[{
                                required : true,
                                message : 'Email is required',
                            }]}>
                           <Input type='email' onChange={(e)=>{setEmail_value(e.target.value)}}  placeholder="Please enter your mail" className="blog-inputs" style={{width:'100%',borderRadius:5,borderColor:'gray',borderWidth:0.5,fontFamily:'madeEvolve'}} /> 
                              
                        </FormItem>
                    </div>
                    </div>
                    <div className='form-group w-100'>
                        <label style={{fontFamily:'madeEvolve', color: 'rgb(15, 48, 122)'}}>Subject</label>
                        <FormItem  rules={[{
                                required : false,
                                message : 'Subject is required',
                            }]} name={`subject`} >
                            <textarea  value={text_value} onChange={(e)=>{setSubject_value(e.target.value)}}   className='form-control formInput'/>
                        </FormItem>
                    </div>
                    <div className='form-group w-100'>
                        <label style={{fontFamily:'madeEvolve', color: 'rgb(15, 48, 122)'}}>Message</label>
                        <FormItem  rules={[{
                                required : false,
                                message : 'Message is required',
                            }]} name={`message`} >
                            <textarea  value={text_value} onChange={(e)=>{setMessage_value(e.target.value)}}  className='form-control formInput'style={{maxHeight:600,height:90}}/>
                        </FormItem>
                    </div>
                    <div className='form-group w-100'>
                    <FormItem  rules={[{
                        required : false,
                        message : 'Message is required',
                    }]} name={`checkUpdateValue`} >
                    <Checkbox onChange={onChangeCheckBox} value={checkUpdate} checked={checkUpdate} style={{
                        fontFamily: 'madeEvolve',
                        whiteSpace: 'nowrap',
                        fontSize: '13px',
                        overflow: 'hidden',
                        color: 'rgb(15, 48, 122)',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%', // Make sure it scales with the container
                        }}>Get updates and subscribe to Newsletter</Checkbox>
                        </FormItem>
                     </div>
                     <div className='form-group w-100 text-center'>
                        <Button
                            type="primary"
                            className='seven_button'
                            htmlType="submit"
                            style={{
                            fontFamily: 'madeEvolve',
                            alignSelf:'center',
                            margin: "auto",
                            fontWeight: "bold",
                            }}
                        >
                            {loading === true ? <Spin indicator={antIcon} /> : "Submit"}
                    </Button>
                    </div>
                </Form>
            </div>
        </div>
        )
    }
return (
  <div>
   {visible? 
   <Modal className='login_modal' title="Login" footer={null} visible={true} style={{backgroundColor:'GrayText'}}>
        <Form
            initialValues={{remember:true}}
            name = "forget_password"
            form={form}
            onFinish={onFinish}
            onChange={change}
            onFinishFailed={onFinishFailed}
            layout="inline"
        >
            <div className='login_form'>
                <label>Password</label>
                <FormItem  rules={[{
                        message : 'password seems wrong',
                        validator : (_,value)=>{
                            if(value == 'jd@1234'){
                                return Promise.resolve();
                            }else{
                                return Promise.reject('password not match')
                            }
                        }
                    }]} name={`password`} >
                    <Input type={`password`} placeholder="Please enter your password" /> 
                </FormItem>
            </div>

            <Button type="primary" className='login_submit_button' htmlType="submit">Submit</Button>

        </Form>
  </Modal>:null}
  </div>
    )
}

export default DemoFive;
