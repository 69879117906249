export const FETCH_BLOGS_LIST = "FETCH_BLOGS_LIST";
export const FETCH_BLOGS_LIST_SUCCESS = "FETCH_BLOGS_LIST_SUCCESS";
export const FETCH_BLOGS_LIST_FAILURE = "FETCH_BLOGS_LIST_FAILURE";

export const FETCH_FAIRYTALES_LIST = "FETCH_FAIRYTALES_LIST";
export const FETCH_FAIRYTALES_LIST_SUCCESS = "FETCH_FAIRYTALES_LIST_SUCCESS";
export const FETCH_FAIRYTALES_LIST_FAILURE = "FETCH_FAIRYTALES_LIST_FAILURE";

export const FETCH_REFLECTIONS_LIST = "FETCH_REFLECTIONS_LIST";
export const FETCH_REFLECTIONS_LIST_SUCCESS = "FETCH_REFLECTIONS_LIST_SUCCESS";
export const FETCH_REFLECTIONS_LIST_FAILURE = "FETCH_REFLECTIONS_LIST_FAILURE";

export const FETCH_POEMS_LIST = "FETCH_POEMS_LIST";
export const FETCH_POEMS_LIST_SUCCESS = "FETCH_POEMS_LIST_SUCCESS";
export const FETCH_POEMS_LIST_FAILURE = "FETCH_POEMS_LIST_FAILURE";

export const FETCH_TAGS_LIST = "FETCH_TAGS_LIST";
export const FETCH_TAGS_LIST_SUCCESS = "FETCH_TAGS_LIST_SUCCESS";


export const FETCH_ABOUT_ME = "FETCH_ABOUT_ME";
export const FETCH_ABOUT_ME_SUCCESS = "FETCH_ABOUT_ME_SUCCESS";
export const FETCH_ABOUT_ME_FAILURE = "FETCH_ABOUT_ME_FAILURE";

export const FETCH_CONTACT = "FETCH_CONTACT";
export const FETCH_CONTACT_SUCCESS = "FETCH_CONTACT_SUCCESS";
export const FETCH_CONTACT_FAILURE = "FETCH_CONTACT_FAILURE";

export const ADD_POEMS = "ADD_POEMS";
export const ADD_REFLECTIONS = "ADD_REFLECTIONS";
export const ADD_FAIRYTALES = "ADD_FAIRYTALES";
export const ADD_BLOGS = "ADD_BLOGS";
export const DELETE_POEMS = "DELETE_POEMS";
export const ADD_ABOUT_ME = "ADD_ABOUT_ME";
export const ADD_TOP_IMAGE = "ADD_TOP_IMAGE";
export const ADD_TAGS_LIST = "ADD_TAGS_LIST";

export const SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE';


