import React from 'react';
import ReactDOM from 'react-dom';
import { createStore} from 'redux'
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
// import CoreReducer from './Reducers/coreReducer'
import reportWebVitals from './reportWebVitals';

// const store =createStore(CoreReducer);
ReactDOM.render(
  // <Provider store={store}>
        <App />
  // </Provider>,
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
