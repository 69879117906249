import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./App.css";
import Blog from "./Blog_page"
import Blog_creation from "./Blog_creation"
import Contact from "./Components/pages/Contact";
import AboutMe from "./Components/pages/AboutMe";
import Fairytale from "./Components/pages/Fairytale";
import 'antd/dist/reset.css';
import { Provider } from "react-redux";
import store from "./utils/configureStore";
import Poems from "./Components/pages/Poems";
import Reflections from "./Components/pages/Reflections";
import imgIcon from "./images/new-menu-bg";
import iconNow from "../src/images/offline_Icon.png";
import { useLocation } from 'react-router-dom';
import { trackingID } from "./config";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Load Google Analytics script once
    const trackingIDs = trackingID;
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingID}`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', trackingIDs);

  }, []); // Empty dependency array to load the script only once

  useEffect(() => {
    const onlineHandler = () => setIsOnline(true);
    const offlineHandler = () => setIsOnline(false);

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <div>
      {isOnline ? (
        <Provider store={store}>
          <BrowserRouter>
            <TrackPageViews />
            <Routes>
              <Route path="/" exact element={<Blog />} />
              <Route path="/blogcreation" element={<Blog_creation />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/aboutme" element={<AboutMe />} />
              <Route path="/fairytale" element={<Fairytale />} />
              <Route path="/poem" element={<Poems />} />
              <Route path="/reflections" element={<Reflections />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <img
            src={iconNow}
            style={{
              width: "150px",
              height: "150px",
              alignSelf: 'center',
              marginTop: '250px',
            }}
            alt="Offline Icon"
          />
          <div className="onlineDivText">
            <p className="onlineText">You are offline. Please check your internet connection.</p>
          </div>
        </div>
      )}
    </div>
  );
}

// Child component for handling tracking and location updates
function TrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    // Track page views on route change
    const trackingIDs = trackingID;
    if (window.gtag) {
      window.gtag('config', trackingIDs, {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null;
}

export default App;
